import * as api from "@/api";
import globals from "@/globals";
import { useAppDispatch } from "@/redux/hooks";
import { setIsLoadingScreenShown } from "@/redux/reducers/app";
import styles from "@/styles/Paypal.module.css";
import { PaymentQuery } from "@/types";
import { showDialog } from "@/utils";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CloseButton, Dialog, DialogPanel } from "@headlessui/react";
import { OnApproveData } from "@paypal/paypal-js/types/components/buttons";
import { PayPalButtons } from "@paypal/react-paypal-js";
import classNames from "classnames";
import { t } from "i18next";
import { JSX, useState } from "react";

interface PaypalProps {
	paid: () => void;
	query: PaymentQuery;
	updateQuery: (newValue: PaymentQuery) => void;
}

function Paypal({ paid, query, updateQuery }: PaypalProps): JSX.Element {
	const dispatch = useAppDispatch();

	const [isClosing, setIsClosing] = useState<boolean>(false);

	const closePopup = (): void => {
		setIsClosing(true);
		setTimeout(() => {
			updateQuery({});
			setIsClosing(false);
		}, globals.ANIMATION_WAIT_TIME);
	};

	const createOrder = async (): Promise<string> => {
		try {
			return await api.createPaypalOrder(query);
		} catch (error) {
			api.handleApiError(dispatch, error);
			throw error;
		} finally {
			dispatch(setIsLoadingScreenShown(false));
		}
	};

	const handleApprove = async (data: OnApproveData): Promise<void> => {
		try {
			await api.verifyPaypalOrder(data.orderID, query, () => {
				void showDialog(dispatch, t("transactionNotCompleted"));
			});
			void showDialog(dispatch, t("thankForPurchasing"));
		} catch (error) {
			api.handleApiError(dispatch, error);
		} finally {
			dispatch(setIsLoadingScreenShown(false));
			paid();
		}
	};

	return (
		<Dialog
			onClose={closePopup}
			open={!!query.month}
		>
			<div
				className={classNames({
					"blur": true,
					"fade-in": !isClosing,
					"fade-out": isClosing,
					"overlay": true,
				})}
			>
				<DialogPanel
					className={classNames({
						[styles["bottom-popup"]]: true,
						"slide-out-to-bottom": isClosing,
					})}
				>
					<CloseButton
						className={styles["btn-close-pop"]}
						title={t("close")}
					>
						<FontAwesomeIcon
							icon={faXmark}
							fixedWidth
						/>
					</CloseButton>
					<PayPalButtons
						className={styles["bottom-popup-content"]}
						createOrder={createOrder}
						onApprove={handleApprove}
					/>
				</DialogPanel>
			</div>
		</Dialog>
	);
}

export default Paypal;
