import globals from "@/globals";
import styles from "@/styles/CaptchaIframe.module.css";
import { encodeData } from "@/utils";
import classNames from "classnames";
import { t } from "i18next";
import { JSX, useRef } from "react";

function CaptchaIframe(): JSX.Element {
	const iframeRef = useRef<HTMLIFrameElement>(null);

	const handleLoad = (): void => {
		iframeRef.current?.focus();
	};

	return (
		<div
			className={classNames(
				"overlay",
				"blur",
				styles["captcha"],
				"fade-in",
			)}
		>
			<iframe
				ref={iframeRef}
				src={
					"https://www.retiehe.com/captcha?" +
					encodeData({
						region: globals.dynamicInfo.region,
					})
				}
				title={t("captcha")}
				onLoad={handleLoad}
			></iframe>
		</div>
	);
}

export default CaptchaIframe;
