import * as api from "@/api";
import ButtonBlock from "@/components/ButtonBlock";
import RealIdVerification from "@/components/RealIdVerification";
import globals from "@/globals";
import { useAppDispatch } from "@/redux/hooks";
import { setIsLoadingScreenShown } from "@/redux/reducers/app";
import styles from "@/styles/BlockedPage.module.css";
import { BlockedTab, RealIdLevel } from "@/types";
import { showCaptcha, showDialog, showPrompt, sleep } from "@/utils";
import classNames from "classnames";
import { t } from "i18next";
import { ChangeEvent, ClipboardEvent, JSX, useId, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface BlockedLocationState {
	autoBlock?: boolean;
	initialTab?: BlockedTab;
	reason?: string;
}

interface BlockedPageProps {
	loggedIn: () => void;
}

function BlockedPage({ loggedIn }: BlockedPageProps): JSX.Element {
	const dispatch = useAppDispatch();
	const location = useLocation();
	const locationStates = location.state as BlockedLocationState | undefined;
	const navigate = useNavigate();

	const pledgeId = useId();

	const [pledge, setPledge] = useState<string>("");
	const [pledgeInput, setPledgeInput] = useState<string>("");
	const [tab, setTab] = useState<BlockedTab>(
		locationStates?.initialTab || BlockedTab.BLOCKED,
	);

	const autoBlock = globals.params.auto === "1" || locationStates?.autoBlock;
	const reason =
		globals.params.reason ||
		locationStates?.reason ||
		t("violationOfTermsOfService");

	const pledgeElement = pledge.split("").map((char, index) => {
		const isCorrect = char === pledgeInput[index];
		return (
			<span
				className={classNames({
					[styles["correct"]]: isCorrect,
					[styles["incorrect"]]:
						pledgeInput.length > index && !isCorrect,
				})}
				key={index}
			>
				{char}
			</span>
		);
	});

	const applyForUnblocking = async (): Promise<void> => {
		if (!globals.login.username) {
			void navigate("/login", {
				replace: true,
			});
			return;
		}
		dispatch(setIsLoadingScreenShown(true));
		try {
			const data = await api.checkSelfUnblockEligibility();
			dispatch(setIsLoadingScreenShown(false));
			if (data.alert) {
				await showDialog(dispatch, data.alert);
			}
			if (data.pledge) {
				await showDialog(dispatch, t("youAreEligibleForSelfUnblock"), {
					countdown: 5,
					showCancel: true,
				});
				setTab(BlockedTab.PLEDGE);
				setPledge(data.pledge);
			} else {
				window.open(
					"https://docs.retiehe.com/account/unblock.html#%E4%BA%BA%E5%B7%A5%E8%A7%A3%E5%B0%81",
				);
			}
		} catch (error) {
			api.handleApiError(dispatch, error);
			dispatch(setIsLoadingScreenShown(false));
		}
	};

	const cheatingDetected = (action: "copy" | "paste"): void => {
		void showDialog(
			dispatch,
			t(action + "Detected") + t("youHaveLostEligibilityToSelfUnblock"),
		);
		setTab(BlockedTab.BLOCKED);
		try {
			void api.disableSelfUnblock();
		} catch (error) {
			api.handleApiError(dispatch, error);
		}
	};

	const deleteBlockedAccount = async (): Promise<void> => {
		if (!globals.login.username) {
			void navigate("/login", {
				replace: true,
			});
			return;
		}
		await showDialog(dispatch, t("confirmDeleteBlockedAccount"), {
			countdown: 30,
			showCancel: true,
		});
		await sleep(globals.ANIMATION_WAIT_TIME);
		await showDialog(dispatch, t("willDeleteAllData"), {
			countdown: 5,
			showCancel: true,
		});
		await sleep(globals.ANIMATION_WAIT_TIME);
		const password = await showPrompt(dispatch, t("enterThePassword"), {
			type: "password",
		});
		await sleep(globals.ANIMATION_WAIT_TIME);
		const confirmation = await showPrompt(
			dispatch,
			t("enterToConfirmDeletion", {
				value: t("deleteAccount").toString(),
			}),
		);
		if (confirmation.toLowerCase() !== t("deleteAccount").toLowerCase()) {
			return;
		}
		const captchaResponse = await showCaptcha(dispatch);
		dispatch(setIsLoadingScreenShown(true));
		try {
			const data = await api.deleteBlockedAccount(
				password,
				captchaResponse,
			);
			dispatch(setIsLoadingScreenShown(false));
			if (data.alert) {
				await showDialog(dispatch, data.alert);
			}
			if (!data.success) {
				return;
			}
			globals.login = {};
			await showDialog(dispatch, t("accountDeleted"));
			void navigate("/login", {
				replace: true,
			});
		} catch (error) {
			api.handleApiError(dispatch, error);
			dispatch(setIsLoadingScreenShown(false));
		}
	};

	const handlePledgeInputChange = (
		event: ChangeEvent<HTMLTextAreaElement>,
	): void => {
		const newValue = event.target.value;
		if (pledgeInput.length === 0 && newValue === pledge) {
			cheatingDetected("paste");
			return;
		}
		setPledgeInput(event.target.value);
	};

	const handleCopy = (event: ClipboardEvent<HTMLParagraphElement>): void => {
		event.preventDefault();
		cheatingDetected("copy");
	};

	const handlePaste = (event: ClipboardEvent<HTMLTextAreaElement>): void => {
		event.preventDefault();
		cheatingDetected("paste");
	};

	const handleSubmitClick = (): void => {
		void submitUnblock();
	};

	const realIdCallback = (): void => {
		loggedIn();
		void navigate("/", {
			replace: true,
		});
	};

	const submitUnblock = async (): Promise<void> => {
		const captchaResponse = await showCaptcha(dispatch);
		dispatch(setIsLoadingScreenShown(true));
		try {
			const unblockResult = await api.selfUnblock(captchaResponse);
			if (!unblockResult.success) {
				dispatch(setIsLoadingScreenShown(false));
				if (unblockResult.alert) {
					void showDialog(dispatch, unblockResult.alert);
				}
				return;
			}
			const profile = await api.getProfile(["realId"]);
			dispatch(setIsLoadingScreenShown(false));
			if (
				!profile.realIdLevel ||
				profile.realIdLevel < RealIdLevel.REAL_ID_VERIFIED
			) {
				setTab(BlockedTab.REAL_ID_REQUIRED);
			} else {
				realIdCallback();
			}
		} catch (error) {
			api.handleApiError(dispatch, error);
			dispatch(setIsLoadingScreenShown(false));
		}
	};

	return (
		<div className={classNames("dark", styles["blocked-page"])}>
			{tab === BlockedTab.BLOCKED && (
				<main>
					<h1 className={styles["title"]}>{t("blocked")}</h1>
					<p className={styles["description"]}>
						{t("dueToBlockReason", {
							detectedByRobot: autoBlock
								? t("detectedByRobot").toString()
								: "",
							reason: reason,
						})}
						<br />
						{t("accountBlocked")}
						<br />
						{t("ifYouHaveAnyQuestionsContactUs")}
					</p>
				</main>
			)}
			{tab === BlockedTab.REAL_ID_REQUIRED && (
				<main>
					<RealIdVerification
						callback={realIdCallback}
						show={true}
					/>
				</main>
			)}
			{tab === BlockedTab.PLEDGE && (
				<main>
					<label htmlFor={pledgeId}>
						{t("manuallyTypeFollowingInTextBox")}
					</label>
					<p>{t("doNotCopyOrPaste")}</p>
					<p
						className={styles["pledge"]}
						onCopy={handleCopy}
						onCut={handleCopy}
					>
						{pledgeElement}
					</p>
					<textarea
						id={pledgeId}
						placeholder={t("enterHere")}
						value={pledgeInput}
						onChange={handlePledgeInputChange}
						onPaste={handlePaste}
					></textarea>
					{pledgeInput === pledge && (
						<ButtonBlock onClick={handleSubmitClick}>
							{t("submit")}
						</ButtonBlock>
					)}
				</main>
			)}
			<footer>
				{tab === BlockedTab.BLOCKED && (
					<button
						onClick={() => void applyForUnblocking()}
						type="button"
					>
						{t("applyForUnblocking")}
					</button>
				)}
				<button
					className={styles["danger"]}
					onClick={() => void deleteBlockedAccount()}
					type="button"
				>
					{t("deleteAccount")}
				</button>
			</footer>
		</div>
	);
}

export default BlockedPage;
