import App from "@/components/App";
import globals from "@/globals";
import store from "@/redux/store";
import "@/styles/globals.css";
import translationEnUs from "@/translations/en-us.json";
import translationZhCn from "@/translations/zh-cn.json";
import translationZhTw from "@/translations/zh-tw.json";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faDisplay,
	faGlobe,
	faMobileScreenButton,
	faTabletScreenButton,
} from "@fortawesome/free-solid-svg-icons";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import i18n, { t } from "i18next";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

const queryClient = new QueryClient();

if (window.location.hostname !== "localhost") {
	Sentry.init({
		dsn: "https://cba8d257f48830e921f9c9de822a4557@o4508388789387264.ingest.us.sentry.io/4508405910667264",
	});
}

function readSystemTheme(): boolean {
	const themeMatch = matchMedia("(prefers-color-scheme: dark)");
	themeMatch.onchange = (event): void => {
		if (event.matches) {
			document.documentElement.classList.add("dark");
		} else {
			document.documentElement.classList.remove("dark");
		}
	};
	return themeMatch.matches;
}

const themeParam = globals.params.theme;
if (
	themeParam === "dark" ||
	(((!themeParam && window.self === window.top) || themeParam === "system") &&
		readSystemTheme())
) {
	document.documentElement.classList.add("dark");
}

const i18nResources = {
	"en-US": {
		translation: translationEnUs,
	},
	"zh-CN": {
		translation: translationZhCn,
	},
	"zh-TW": {
		translation: translationZhTw,
	},
};

const lang = ((): string => {
	const langParam = globals.params.lang;
	if (langParam && i18nResources[langParam as keyof typeof i18nResources]) {
		return langParam;
	} else if (
		!navigator.language ||
		/^(yue|zh)(-cn|-sg|-hans(-[a-z]+)?)?$/i.test(navigator.language) ||
		(/bot|spider/i.test(navigator.userAgent) &&
			!navigator.userAgent.includes("Googlebot"))
	) {
		return "zh-CN";
	} else if (
		navigator.language.startsWith("zh") ||
		navigator.language.startsWith("yue")
	) {
		return "zh-TW";
	} else {
		return "en-US";
	}
})();

i18n.init({
	fallbackLng: "en-US",
	interpolation: {
		escapeValue: false,
	},
	lng: lang,
	resources: i18nResources,
})
	.then(() => {
		document.title = t("retieheAccount");
		if (!navigator.cookieEnabled) {
			window.alert(t("cookieDisabled"));
		}
		if (!lang.startsWith("zh")) {
			globals.home = "https://www.retinbox.com/";
		}
	})
	.catch(console.error);

library.add(faDisplay, faGlobe, faMobileScreenButton, faTabletScreenButton);

document.documentElement.lang = lang;

if (process.env.NODE_ENV === "production") {
	disableReactDevTools();
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
	<StrictMode>
		<ReduxProvider store={store}>
			<QueryClientProvider client={queryClient}>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</QueryClientProvider>
		</ReduxProvider>
	</StrictMode>,
);
